/**
 * @see http://download.oracle.com/javase/6/docs/api/java/util/Collection.html
 */
export default class Collection {
  /**
     * Ensures that this collection contains the specified element (optional
     * operation).
     * @param {Object} e
     * @return {boolean}
     */
  add() { }
  /**
     * Appends all of the elements in the specified collection to the end of this
     * list, in the order that they are returned by the specified collection's
     * iterator (optional operation).
     * @param {javascript.util.Collection} c
     * @return {boolean}
     */
  addAll() { }
  /**
     * Returns true if this collection contains no elements.
     * @return {boolean}
     */
  isEmpty() { }
  /**
     * Returns an iterator over the elements in this collection.
     * @return {javascript.util.Iterator}
     */
  iterator() { }
  /**
     * Returns an iterator over the elements in this collection.
     * @return {number}
     */
  size() { }
  /**
     * Returns an array containing all of the elements in this collection.
     * @return {Array}
     */
  toArray() { }
  /**
     * Removes a single instance of the specified element from this collection if it
     * is present. (optional)
     * @param {Object} e
     * @return {boolean}
     */
  remove() { }
}
